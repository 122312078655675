import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import bg from "../public/images/ChosenBg.png";
const Container = styled.div`
  height: 100vh;
  width: 17%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  background-size: cover;
  position: fixed;
  @media (max-width: 600px) {
    width: 0%;
    display: none;
  }
`;
const LogoBox = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
`;
const LogoImg = styled.img`
  width: 100px;
  height: auto;
  margin-top:10px;
  @media (max-width: 769px) {
    width: 80px;
    height: auto;
  }
`;
const Submenus = styled.div`
  //background-color: gold;
  padding: 0.77rem;
  @media (max-width: 769px) {
    padding: 0;
  }
`;
const ListOfItems = styled.ul`
  list-style: none;
  padding-left: 0;
`;
const Item = styled.li`
  padding: 12px;
  padding-left: 10px;
  padding-bottom: 0;
`;
const ItemLink = styled(Link)`
  color: #34495e;
`;
const ItemIconBox = styled.span`
  @media (max-width: 1000px) {
    display:none;
  }
`;
const ItemTitle = styled.h5`
  display: inline-block;
  padding-left: 12px;
  @media (max-width: 1000px) {
    font-size:16px;
  }
`;
const FooterBox = styled.div`
  height: 30%;
  //background-color:red;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 769px) {
    height: auto;
  }
`;
const FooterImg = styled.img`
  width: 80%;
  height: auto;
  @media (max-width: 769px) {
    width: 100%;
  }
`;
export {
  Container,
  LogoBox,
  LogoImg,
  Submenus,
  ListOfItems,
  Item,
  ItemLink,
  ItemIconBox,
  ItemTitle,
  FooterBox,
  FooterImg,
};
