import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  display:flex;
  flex-direction:column;
  //justify-content:center;
  align-items:center;
  margin-top:7px;
  width:100%;
`;
const HeaderSelector = styled.div`
  display:flex;
  flex-direction:row;
  background-color:white;
  width:50%;
  border-radius:12px;
  justify-content:space-between;
  padding:8px;
  align-items:center;
  @media (max-width: 769px) {
    width: 80%;
  }
`;
const ArrowToLeft = styled.img`
  height:40px;
  width:auto;
`;
const ArrowToRight = styled.img`
  height:40px;
  width:auto;
`;
const TheTitle = styled.h2`
  color:gray;
`;
const CantineItem = styled.div`
  margin:15px;
  border-radius:8px;
  width:40%;
  max-width:55%;
  align-self:flex-start;
  align-self:center;
  @media (max-width: 769px) {
    width: 60%;
    max-width:80%;
  }
`;
const ElemBox = styled.div`
  background-color:white;
  box-shadow: 7px 6px 15px -8px rgba(0,0,0,0.8);
  display:flex;
  flex-direction:row;
  margin:15px;
  border-radius:15px;
`;
const Box1 = styled.div`
  background-color:${props => props.Color ? props.Color :"#f39c12"};
  width:70px;
  height:50px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin:7px;
  border-radius:15px;
`;
const SubTitle = styled.h5`
  color:white;
  font-size:19px;
  text-align:center;
  margin:0;
`;
const Box2 = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left:5px;
`;
const ElemTitle = styled.div`
  color:${props => props.Color ? props.Color :"#f39c12"};
`;
const ElemBody =styled.div`
  text-decoration:bold;
`;
export {
  Container,
  HeaderSelector,
  ArrowToLeft,
  ArrowToRight,
  TheTitle,
  CantineItem,
  ElemBox,
  Box1,
  SubTitle,
  Box2,
  ElemTitle,
  ElemBody,
}