/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { baseURL } from "../constants/env";
import Logo from "../public/images/logo.png";

import result from "../public/Icons/HomePage/result.png";
import paiement from "../public/Icons/HomePage/paiement.png";
import homework from "../public/Icons/HomePage/homework.png";
import examens from "../public/Icons/HomePage/examens.png";
import emplois from "../public/Icons/HomePage/emplois.png";
import elearning from "../public/Icons/HomePage/elearning.png";
import calendrier from "../public/Icons/HomePage/calendrier.png";
import cantine from "../public/Icons/HomePage/cantine.png";
import {
  Container,
  Header,
  LogoBox,
  TheLogo,
  DesciptionBox,
  Avatar,
  InfoBox,
  Name,
  Classe,
  Content,
  BtnLink,
  BtnBox,
  BtnIconContainer,
  BtnIcon,
  BtnTitle,
} from "../styles/Home";
const Home = () => {
  const [items, setitems] = useState([
    {
      icon: result,
      name: "Résultats",
      route: "/result",
      color: "#4358d1",
    },
    {
      icon: cantine,
      name: "Cantine",
      route: "/cantine",
      color: "#f43b3b",
    },
    {
      icon: emplois,
      name: "Emplois",
      route: "/emploi",
      color: "#fabc00",
    },
    {
      icon: calendrier,
      name: "Calandrier",
      route: "/calender",
      color: "#f4006e",
    },
    {
      icon: homework,
      name: "Homeworks",
      route: "/homeworks",
      color: "#74db52",
    },
    {
      icon: elearning,
      name: "E-Learning",
      route: "/elearning",
      color: "#d100c6",
    },
    {
      icon: examens,
      name: "Examens",
      route: "/examen",
      color: "#2f94e4",
    },

    {
      icon: paiement,
      name: "Paiement",
      route: "/payment",
      color: "#fae000",
    },
    {
      icon: elearning,
      name: "Notifications",
      route: "/notifications",
      color: "#d100c6",
    },
  ]);
  const [username, setusername] = useState(null);
  const [className, setclassName] = useState(null);
  const [picture, setpicture] = useState(null);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log("user ", user);
    if (user && user.profil) {
      setusername(user.profil.firstName + " " + user.profil.lastName);
      setclassName(user.classe.name);
      setpicture(baseURL + user.profil.picture);
    }
  }, []);
  return (
    <Container>
      <Header>
        <LogoBox to="/home">
          <TheLogo src={Logo} />
        </LogoBox>
        <DesciptionBox>
          <Avatar src={picture} />
          <InfoBox>
            <Name>{username}</Name>
            <Classe>{className}</Classe>
          </InfoBox>
        </DesciptionBox>
      </Header>
      <Content>
        {items.map((elem, index) => {
          return (
            <BtnBox color={elem.color}>
              <BtnLink to={elem.route} id={index} color={elem.color}>
                <BtnIconContainer>
                  <BtnIcon src={elem.icon} alt={elem.name} />
                </BtnIconContainer>
                <BtnTitle>{elem.name}</BtnTitle>
              </BtnLink>
            </BtnBox>
          );
        })}
      </Content>
    </Container>
  );
};
export default Home;
