import React, { useEffect,useState } from "react";
import swal from "sweetalert";
import {
  Container,
  HeaderSelector,
  ArrowToLeft,
  ArrowToRight,
  TheTitle,
  CantineItem,
  PasCoursBox,
  PasCoursText,
  ElemBox,
  Box1,
  SubTitle,
  Box2,
  ElemTitle,
  ElemBody,
} from "../styles/Elearning";
import { API } from "../constants/env";
import ArrowLeft from "../public/images/ArrowLeft.png";
import ArrowRight from "../public/images/ArrowRight.png";
import ArrowUp from "../public/images/ArrowUp.png";
import ArrowDown from "../public/images/ArrowDown.png";
import Item from "../libs/generalComponants/Item";

const Elearning = () => {
  const [weekDays, setWeekDays] = useState([[],[],[],[],[],[]]);
  const [days, setdays] = useState(["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"]);
  const [selectedDay, setselectedDay] = useState(0);
  const [loading, setloading] = useState(false)
  let colors =[
        '#fabc00','#4358d1','#f4006e','#d100c6','#f43b3b','#74db52'
  ];
  useEffect(() => {
    fetch(API.elearning.list, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          // console.log(response);
          return swal("Error", response.message, "error");
        }
        let Aux=[[],[],[],[],[],[]];
        response.data.forEach(({day,title,type,teacher,subject,files,content,created_at})=>{
          let time = new Date(created_at);
          Aux[parseInt(day)].push({
                title,
                type,
                subject:subject?.name,
                files,
                content,
                time:`${time.getDate()}/${time.getMonth()+1}/${time.getFullYear()}`,
          })
        });
        setWeekDays(Aux);
        setloading(true);
      })
      .catch((e) => {
        if (e.message === "Failed to fetch") {
          swal("Erreur", "Probléme de connection", "error");
        }
      });
  }, []);
  const nextFunc = (number)=>{
        console.log("Clicked");
        if(number > 0 ){
            setselectedDay(Math.abs((selectedDay+number)%6));
        }else{
            setselectedDay((selectedDay-number)%6);
            console.log("left arrow")
        }
        console.log("selectedDay = ",selectedDay);
    }
  const renderItems = ()=>{
    if(loading){
      if(weekDays && weekDays[selectedDay].length > 0){
        return weekDays[selectedDay]?.map(({title,type,time,subject,files,content},index)=>{
          return (<Item title={title}
                type={type}
                time={time}
                subject={subject}
                files={files}
                content={content}
                index={index}/>)
        });
      }else{
        return (
          <PasCoursBox>
            <PasCoursText>E-learning n'existe pas</PasCoursText>
          </PasCoursBox>)
      }
    }
  }
  return (
    <Container>
      <HeaderSelector>
        <ArrowToLeft src={ArrowLeft}  onClick={()=>{nextFunc(-5)}}/>
        <TheTitle>{days[selectedDay]}</TheTitle>
        <ArrowToRight src={ArrowRight} onClick={()=>{nextFunc(1)}}/>
      </HeaderSelector>
      <CantineItem>
        {renderItems()}
      </CantineItem>
    </Container>
    );
};

export default Elearning;
