/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./components/Login";
import { routes } from "./constants/routes";
import { ROUTER } from "./constants/env";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: localStorage.getItem("token"),
    };
  }
  render() {
    return this.state.authenticated && this.state.authenticated !== "false" ? (
      <div>
        <BrowserRouter>
          <Switch>
            {routes.map((elem, index) => {
              return (
                <Route
                  key={index}
                  path={elem.path}
                  exact={elem.exact}
                  component={elem.main}
                />
              );
            })}
            {/* <Redirect to={ROUTER.ERROR} /> */}
          </Switch>
        </BrowserRouter>
      </div>
    ) : (
      <div>
        <BrowserRouter forceRefresh={true}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/" render={() => <Redirect to="/login" />} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
