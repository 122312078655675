import React from "react";
import Sremarque from "./Sremarque";

const roundN = (num1) => {
  const num = num1?.toString(); //If it's not already a String
  const res = num.slice(0, num.indexOf(".") + 3); //With 3 exposing the hundredths place
  return res;
};

const Smoyenne = ({
  moyenne,
  fontSizeTitle,
  title,
  fontSizeMoyenne,
  color,
  remarque,
  general,
  unitInedx,
}) => {
  return (
    <div className="card ">
      <div className="card-body">
        <header
          style={{
            color: color,
            fontWeight: "bolder",
            fontSize: fontSizeTitle,
            marginBottom: 2,
          }}
        >
          {title}
        </header>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              flex: "0 0 35%",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontSize: fontSizeMoyenne,
                color: "#474444",

                fontWeight: 800,
              }}
            >
              {moyenne ? roundN(moyenne) : 0}
            </span>
          </div>
          <div style={{ flex: 1 }}>
            <Sremarque
              general={general}
              unitInedx={unitInedx}
              color={color}
              remarque={remarque}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Smoyenne;
