import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API } from "../../constants/env";

const SmoyenneG = ({ title, fontSizeMoyenne, color }) => {
  const [allC, setAllC] = useState({});
  useEffect(() => {
    fetch(API.allAverge.get(JSON.parse(localStorage.getItem("user"))._id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response) {
          setAllC({ moyenne: 0, max: 0, min: 0 });
        } else if (response.error) {
          return swal("Error", response.message, "error");
        } else {
          setAllC(response);
        }
      })
      .catch((e) => {
        swal("Error", e.message, "error");
      });
  }, []);

  return (
    <div className="card" style={{ marginTop: 10 }}>
      <div className="card-body">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              flex: 1,
            }}
          >
            <h1
              style={{
                textAlign: "center",
                margin: "0 auto",
                fontSize: fontSizeMoyenne,
                color: "#474444",
                color: color,
                fontWeight: 600,
              }}
            >
              {title}
              <br />
              <input
                type="number"
                name="moyenne"
                placeholder="00,00"
                disabled
                step="0.01"
                value={allC?.moyenne}
                style={{ outline: 0, width: "30%" }}
              />
            </h1>
          </div>

          <div style={{ flex: 1 }}>
            <h1
              style={{
                textAlign: "center",
                margin: "0 auto",
                fontSize: fontSizeMoyenne,
                color: "#474444",
                color: color,
                fontWeight: 600,
              }}
            >
              moyenne la plus élevée : <br />
              <input
                disabled
                value={allC?.max}
                type="number"
                name="eleve"
                placeholder="00,00"
                style={{ outline: 0, width: "30%" }}
                step="0.01"
              />
            </h1>
          </div>
          <div style={{ flex: 1 }}>
            <h1
              style={{
                textAlign: "center",
                fontSize: fontSizeMoyenne,
                color: color,
                fontWeight: 600,
              }}
            >
              Moyenne la plus basse : <br />
              <input
                style={{ marginTop: "20px" }}
                type="number"
                name="bas"
                step="0.01"
                placeholder="00,00"
                disabled
                style={{ outline: 0, width: "30%" }}
                value={allC?.min}
              />
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmoyenneG;
