import moment from "moment";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API } from "../constants/env";

import {
  Emploibg,
  Column,
  Day,
  EmploiBoxes,
  EmploiBox,
  Title,
  Description,
  Matiere,
  Time,
} from "../styles/Emploi";

const Emploi = () => {
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    fetch(
      API.emplois.all(JSON.parse(localStorage.getItem("user")).classe._id),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }
        console.log("fetching ......");
        //console.log(response);
        const weekSchedule = response.reduce(
          (sched, { subject, day, endHour, startHour, _id }) => {
            const sH = new Date(startHour);
            const eD = new Date(endHour);

            sched[parseInt(day) - 1].subjects.push({
              name: subject.name,
              shortName: subject.name.slice(0, 2).toUpperCase(),
              day: moment(sH).lang("fr").format("MMMM Do, "),
              start: `${sH.getHours() - 1}:${sH.getMinutes()}`,
              end: `${eD.getHours() - 1}:${eD.getMinutes()}`,
            });
            sched[parseInt(day) - 1].id = _id;
            return sched;
          },
          [
            { day: "Lundi", subjects: [], id: "" },
            { day: "Mardi", subjects: [], id: "" },
            { day: "Mercredi", subjects: [], id: "" },
            { day: "Jeudi", subjects: [], id: "" },
            { day: "Vendredi", subjects: [], id: "" },
          ]
        );
        setSchedule(weekSchedule);
      })
      .catch((e) => {
        if (e.message === "Failed to fetch") {
          swal("Erreur", "Probléme de connection", "error");
        }
      });
  }, []);

  let colors =[
        '#fabc00','#4358d1','#f4006e','#d100c6','#f43b3b','#74db52'
  ];
  return (
    <Emploibg>
      {schedule.map(({ day, subjects, id }) => (
        <Column margin key={id}>
          <Day> {day} </Day>
          <EmploiBoxes>
            {subjects.map(({ name, start, end, shortName, day },index) => (
              <EmploiBox>
                <Title Color={colors[index%6]}>{shortName}</Title>
                <Description>
                  <Matiere>{name}</Matiere>{" "}
                  <Time> {`${start} - ${end}`}</Time>
                </Description>
              </EmploiBox>
            ))}
          </EmploiBoxes>{" "}
        </Column>
      ))}
    </Emploibg>
  );
};

export default Emploi;
