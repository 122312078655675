import React, { useEffect, useState } from "react";
import moment from "moment";
import swal from "sweetalert";
import { API } from "../constants/env";
import ArrowLeft from "../public/images/ArrowLeft.png";
import ArrowRight from "../public/images/ArrowRight.png";
import {
  Container,
  ActionContainer,
  ActionTitle,
  ActionList,
  ActionItem,
  ItemTitle,
  ItemDescription,
  ItemHeader,
  ItemDate,
} from "../styles/Calender";
import {
  ContainerHeader,
  BtnArrowLeft,
  ArrowLeftImg,
  CenterText,
  BtnArrowRight,
  ArrowRightImg,
} from "../styles/Result";

const Calender = () => {
  const [pageNumber, setpageNumber] = useState(new Date().getMonth());
  const [actions, setActions] = useState([]);
  const [titles, setTitles] = useState([]);
  const [registers, setRegisters] = useState([]);
  const Months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  useEffect(() => {
    fetch(
      API.calendar.getRegister(JSON.parse(localStorage.getItem("user"))._id),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setRegisters(response);
      })
      .catch((e) => {
        if (e.message === "Failed to fetch") {
          swal("Erreur", "Probléme de connection", "error");
        }
      });

    fetch(
      API.calendar.getActions(
        JSON.parse(localStorage.getItem("user")).classe._id
      ),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setActions(response);
      })
      .catch((e) => {
        if (e.message === "Failed to fetch") {
          swal("Erreur", "Probléme de connection", "error");
        }
      });

    fetch(API.calendar.getActionType, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setTitles(response);
      })
      .catch((e) => {
        if (e.message === "Failed to fetch") {
          swal("Erreur", "Probléme de connection", "error");
        }
      });
  }, []);

  const nextFunc = (number) => {
    if (number > 0) {
      setpageNumber(Math.abs((pageNumber + number) % 12));
    } else {
      setpageNumber((pageNumber - number + 10) % 12);
    }
  };

  const getActions = (title) => {
    return actions.filter(
      (action) =>
        action.actionType.title === title &&
        new Date(action.date).getMonth() === pageNumber
    );
  };

  const getRegister = (title) => {
    return registers.filter(
      (register) =>
        register.title === title &&
        new Date(register.date).getMonth() === pageNumber
    );
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ContainerHeader>
          <BtnArrowLeft onClick={() => nextFunc(-1)}>
            <ArrowLeftImg src={ArrowLeft} />
          </BtnArrowLeft>
          <CenterText>{`${Months[pageNumber]}`}</CenterText>
          <BtnArrowRight onClick={() => nextFunc(1)}>
            <ArrowRightImg src={ArrowRight} />
          </BtnArrowRight>
        </ContainerHeader>
      </div>
      <Container>
        {getRegister("Punition").length !== 0 && (
          <ActionContainer>
            <ActionTitle>Punitions :</ActionTitle>
            <ActionList>
              {getRegister("Punition")?.map((register) => (
                <ActionItem key={register._id}>
                  <ItemTitle color={"#fcb044"}>
                    {register.subject.name.slice(0, 2).toUpperCase()}
                  </ItemTitle>
                  <ItemDescription>
                    <ItemHeader>{register.subject.name}</ItemHeader>
                    <ItemDate>Punition</ItemDate>
                    <ItemDate>{moment(register.date).format("l")}</ItemDate>
                  </ItemDescription>
                </ActionItem>
              ))}
            </ActionList>
          </ActionContainer>
        )}

        {getRegister("Absence").length !== 0 && (
          <ActionContainer>
            <ActionTitle>Absences :</ActionTitle>
            <ActionList>
              {getRegister("Absence")?.map((register) => (
                <ActionItem key={register._id}>
                  <ItemTitle color={"#ef517b"}>
                    {register.subject.name.slice(0, 2).toUpperCase()}
                  </ItemTitle>
                  <ItemDescription>
                    <ItemHeader>{register.subject.name}</ItemHeader>
                    <ItemDate>Absence</ItemDate>
                    <ItemDate>{moment(register.date).format("l")}</ItemDate>
                  </ItemDescription>
                </ActionItem>
              ))}
            </ActionList>
          </ActionContainer>
        )}

        {titles.map(
          (title) =>
            getActions(title.title).length !== 0 && (
              <ActionContainer key={title._id}>
                <ActionTitle> {title.title} :</ActionTitle>
                <ActionList>
                  {getActions(title.title)?.map((action) => (
                    <ActionItem key={action._id}>
                      <ItemTitle color={title.color}>
                          {action.description.slice(0, 2).toUpperCase()}
                      </ItemTitle>
                      <ItemDescription>
                        <ItemHeader>{action.description}</ItemHeader>
                        <ItemDate>
                          {`${
                            action.period
                              ? moment(action.startDate).format("l") +
                                "-->" +
                                moment(action.endDate).format("l")
                              : moment(action.date).format("l")
                          }`}{" "}
                        </ItemDate>
                      </ItemDescription>
                    </ActionItem>
                  ))}
                </ActionList>
              </ActionContainer>
            )
        )}
      </Container>
    </React.Fragment>
  );
};

export default Calender;
