import styled from "styled-components";
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;
const CantineItem = styled.div`
  background-color: white;
  margin: 15px;
  border-radius: 8px;
  width: 25%;
  // max-width: 25%;
  align-self: flex-start;
  box-shadow: 7px 6px 15px -8px rgba(0, 0, 0, 0.8);
  @media (max-width: 600px) {
    width: 90%;
    align-self: center;
  }
`;
const CantineName = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.BgColor};
  width: 100%;
  padding: 10px;
  text-align: center;

  @media (max-width: 600px) {
    padding: 8px;
    border-radius: 6px;
  }
`;
const CantineText = styled.span`
  //text-align: center;
  padding-top: 7px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  @media (max-width: 600px) {
    padding: 8px;
    border-radius: 6px;
  }
`;
const CantineElem = styled.div``;
const CantineList = styled.ul`
  padding: 15px 25px;
  margin-left: 10px;
`;
const CantineLi = styled.li``;
export {
  Container,
  CantineItem,
  CantineName,
  CantineText,
  CantineElem,
  CantineList,
  CantineLi,
};
