import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import bg from "../public/images/ChosenBg.png";
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(${bg});
  background-size: cover;
`;
const Header = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 80%;
  background-color: white;
  border-radius: 10px 22px;
  padding: 20px;
  margin-top: 1%;

  @media (max-width: 600px) {
    padding: 10px;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    margin-top: 0%;
  }
`;
const LogoBox = styled(Link)`
  display: inline-block;
  align-self: center;
`;
const TheLogo = styled.img`
  width: 90px;
  height: 90px;

  @media (max-width: 600px) {
    width: 60px;
    height: 60px;
  }
`;
const DesciptionBox = styled.div`
  display: inline-block;
  align-self: center;
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-end;
  }
`;
const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border: 1px solid black;
  border-radius: 25px;
  @media (max-width: 600px) {
    width: 45px;
    height: 45px;
  }
`;
const InfoBox = styled.div`
  margin-left: 20px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 13px;
    font-weight: 600;
    margin-left: 20px;
  }
`;
const Name = styled.h5`
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 12px;
    font-weight: 600;

    text-transform: uppercase;
  }
`;
const Classe = styled.h6`
font-size:14px;
  @media (max-width: 600px) {
    font-size: 11px;
  }
`;
const Content = styled.div`
  //background-color:gold;
  display: flex;
  flex-wrap: wrap;
  flex: 0;
  margin: 0 auto;
  width: 80%;
  margin-top: 5%;
  flex-direction: row;

  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }
`;
const BtnLink = styled(Link)`
  /* display: flex;
  justify-content: center;
   */
  color: white;
  &:hover {
    color: white;
  }
  display: flex;
  flex: 1;
  flex-direction: column;
  //justify-content:center;
  align-items: center;
  align-self: center;
  //background-color:green;
`;
const BtnBox = styled.div`
  background-color:red;
  display: flex;
  flex: 1;
  height: 180px;
  min-width: 21%;
  padding: 2%;
  justify-content: center;
  background-color: ${(props) => (props.color ? props.color : "green")};
  margin: 0px 4px 8px 4px;
  border-radius: 10px;

  @media (max-width: 600px) {
    width: 40%;
    margin: 4px 2px;
  }
`;
const BtnIconContainer = styled.div`
  padding-bottom: 17px;
  text-align:center;
`;
const BtnIcon = styled.img`
  width:30%;
  height:auto;
`;
const BtnTitle = styled.h4``;

export {
  Container,
  Header,
  LogoBox,
  TheLogo,
  DesciptionBox,
  Avatar,
  InfoBox,
  Name,
  Classe,
  Content,
  BtnLink,
  BtnBox,
  BtnIconContainer,
  BtnIcon,
  BtnTitle,
};
