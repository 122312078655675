import React from "react";
import Pdf from "../../Pdf/Pdf";
import CarnetTable from "./CarnetTable";

const CarnetP = ({ carnet, pageNumber }) => {
  return (
    <div>
      {carnet?.units?.map((unit, index) => (
        <React.Fragment key={unit.unitId}>
          <CarnetTable
            Ar={!(unit.unitName === "مجال الفرنسية")}
            unit={unit}
            index={index}
          />
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export default CarnetP;
