import React, { useState } from "react";
import NoteR from "./NoteR";

const NotesMark = ({ note, subjectIndex, goalIndex, unitIndex }) => {
  const [currentValue, setCurrentValue] = useState(note);

  return (
    <React.Fragment>
      <NoteR
        text="X"
        number="0"
        setCurrentValue={setCurrentValue}
        currentValue={currentValue}
        subjectIndex={subjectIndex}
        goalIndex={goalIndex}
        unitIndex={unitIndex}
      />
      <NoteR
        text="X"
        number="1"
        setCurrentValue={setCurrentValue}
        currentValue={currentValue}
        subjectIndex={subjectIndex}
        goalIndex={goalIndex}
        unitIndex={unitIndex}
      />
      <NoteR
        text="X"
        number="2"
        setCurrentValue={setCurrentValue}
        currentValue={currentValue}
        subjectIndex={subjectIndex}
        goalIndex={goalIndex}
        unitIndex={unitIndex}
      />
    </React.Fragment>
  );
};

export default NotesMark;
