/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Panel from "./generalComponants/Panel";
import {
  Container,
  BodyContainer,
  Header,
  AccountInfo,
  DesciptionBox,
  Avatar,
  InfoBox,
  Name,
  Classe,
  TitleBox,
  Title,
  ContentBox,
  Content,
  BtnLink,
  BtnBox,
  BtnTitle,
} from "../styles/Template";
import { baseURL } from "../constants/env";
import AvatarIcon from "../public/images/avatar.png";
export default function Template(ComponentToProtect, LeTitle, Color) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        user: null,
        showMenu:false,
      };
    }
    componentDidMount() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.setState({
        user,
      });
    }
    items = [
    {
      name: "Résultats",
      route: "/result",
      color: '#4358d1',
    },
    {
      name: "Cantine",
      route: "/cantine",
      color: '#f43b3b',
    },
    {
      name: "Emplois",
      route: "/emploi",
      color: '#fabc00',
    },
    {
      name: "Calandrier",
      route: "/calender",
      color: '#f4006e',
    },
    {
      name: "Homeworks",
      route: "/homeworks",
      color: '#74db52',
    },
    {
      name: "E-Learning",
      route: "/elearning",
      color: '#d100c6',
    },
    {
      name: "Examens",
      route: "/examen",
      color: '#2f94e4',
    },

    {
      name: "Paiement",
      route: "/payment",
      color: '#fae000',
    },
    {
      name: "Se Déconnecter",
      route: "/logout",
      color: "#e74c3c",
    },
  ]
    handleClickMenu = ()=>{
      this.setState({
                showMenu:!this.state.showMenu
              })
    }
    render() {
      return (
        <Container>
          <Panel />
          <BodyContainer>
            <Header>
              <AccountInfo onClick={()=>{this.handleClickMenu()}}>
                <DesciptionBox>
                  <Avatar src={baseURL + this.state.user?.profil.picture} />
                  <InfoBox>
                    <Name>
                      {this.state.user?.profil.firstName}{" "}
                      {this.state.user?.profil.lastName}
                    </Name>
                    <Classe>{this.state.user?.classe.name}</Classe>
                  </InfoBox>
                </DesciptionBox>
              </AccountInfo>
              {this.state.showMenu ?
              <Content>
                {this.items.map((elem, index) => {
                  return (
                    <BtnBox color={elem.color}>
                      <BtnLink to={elem.route} id={index} color={elem.color}>
                        <BtnTitle>{elem.name}</BtnTitle>
                      </BtnLink>
                    </BtnBox>
                  );
                })}
              </Content>
              :null}
              <TitleBox Color={Color}>
                <Title>{LeTitle}</Title>
              </TitleBox>
            </Header>
            <ContentBox>
              <ComponentToProtect {...this.props} />
            </ContentBox>
          </BodyContainer>
        </Container>
      );
    }
  };
}
