import React, { useState, useEffect } from "react";
import ArrowLeft from "../public/images/ArrowLeft.png";
import ArrowRight from "../public/images/ArrowRight.png";
import CarnetS from "../Carnet/Carnet/CarnetS";
import {
  Container,
  ContainerHeader,
  BtnArrowLeft,
  ArrowLeftImg,
  CenterText,
  BtnArrowRight,
  ArrowRightImg,
} from "../styles/Result";
import { API } from "../constants/env";
import swal from "sweetalert";
import CarnetP from "../Carnet/Carnet Prepa/CarnetP";
import Pdf from "../Pdf/Pdf";
const Result = () => {
  const [carnet, setCarnet] = useState([]);
  const [pageNumber, setpageNumber] = useState(0);

  useEffect(() => {
    fetch(
      JSON.parse(localStorage.getItem("user")).classe?.level.includes("Prepa")
        ? API.resultat.Prepa(JSON.parse(localStorage.getItem("user"))._id)
        : API.resultat.Student(JSON.parse(localStorage.getItem("user"))._id),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }

        setCarnet(response);
      })
      .catch((e) => {
        if (e.message === "Failed to fetch") {
          swal("Erreur", "Probléme de connection", "error");
        }
      });
  }, []);

  const getCarnet = () => {
    const semestres = ["premier", "deuxieme", "troisieme"];
    return carnet.find((car) => car.semestre === semestres[pageNumber]);
  };

  const nextFunc = (number) => {
    console.log("Clicked");
    if (number > 0) {
      setpageNumber(Math.abs((pageNumber + number) % 3));
    } else {
      setpageNumber((pageNumber - number + 1) % 3);
    }
  };

  return (
    <Container>
      <ContainerHeader>
        <BtnArrowLeft
          onClick={() => {
            nextFunc(-1);
          }}
        >
          <ArrowLeftImg src={ArrowLeft} />
        </BtnArrowLeft>
        <CenterText>{`Trimestre ${pageNumber + 1}`}</CenterText>
        <Pdf
          carnetId={getCarnet()?._id}
          number={pageNumber}
          to={JSON.parse(localStorage.getItem("user"))?.classe?.level?.includes("Prepa")? "P":"S"}
        />
        <BtnArrowRight
          onClick={() => {
            nextFunc(1);
          }}
        >
          <ArrowRightImg src={ArrowRight} />
        </BtnArrowRight>
      </ContainerHeader>
      {carnet.length === 0 ? (
        <div
          style={{
            width: "60%",
            height: "40px",
            padding: "50px",
            textAlign: "center",
            backgroundColor: "white",
          }}
        >
          Pas de Carnet
        </div>
      ) : JSON.parse(localStorage.getItem("user"))?.classe?.level?.includes(
          "Prepa"
        ) ? (
        <CarnetP carnet={getCarnet()} pageNumber={pageNumber} />
      ) : (
        <CarnetS carnet={getCarnet()} pageNumber={pageNumber} />
      )}
    </Container>
  );
};
export default Result;
