import React,{useState} from 'react';
import {
  Container,
  HeaderSelector,
  ArrowToLeft,
  ArrowToRight,
  TheTitle,
  CantineItem,
  ElemBox,
  Box1,
  SubTitle,
  Box2,
  ElemTitle,
  ElemBody,
  Box3,
  ArrowIcon,
  ContentContainer,
  ContentBody,
  FilesBox,
  LinkImg,
  ImgShowen
} from "../../styles/generalComponants/Item";
import ArrowUp from "../../public/images/ArrowUp.png";
import ArrowDown from "../../public/images/ArrowDown.png";
import {baseURL} from "../../constants/env";


export default function Item({title,type,time,subject,files,content,index}) {
  const [isDisplayed, setisDisplayed] = useState(false);
  let colors =[
        '#fabc00','#4358d1','#f4006e','#d100c6','#f43b3b','#74db52'
  ];
  function createMarkup() {
    return {__html: content};
  }
  return (
    <Container>
      <ElemBox>
        <Box1 Color={colors[index%5]}>
          <SubTitle>{title.substring(0,2).toUpperCase()}</SubTitle>
        </Box1>
        <Box2>
          <ElemTitle  Color={colors[index%5]}>{title}</ElemTitle>
          <ElemBody>{subject} : {type}  -  {time} </ElemBody>
        </Box2>
        <Box3 onClick={()=>{
          setisDisplayed(!isDisplayed)
        }}>
          {isDisplayed ?
          <ArrowIcon src={ArrowUp} />
          :
          <ArrowIcon src={ArrowDown} />}
        </Box3>
      </ElemBox>
      {isDisplayed ?
        <ContentContainer>
          <ContentBody  dangerouslySetInnerHTML={createMarkup()} />
          <h6>Pièce(s) Jointe(s) :</h6>
          <FilesBox>
          {
            files.map((url)=>{
              return (
                <LinkImg href={baseURL+url} target="_blank">
                  <ImgShowen src={baseURL+url}/>
                </LinkImg>
              )
            })
          }
          </FilesBox>
        </ContentContainer>
        :
        null
      }
      
    </Container>
  )
}
