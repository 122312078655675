import React from "react";

const NoteR = ({
  text,
  number,
  currentValue,
  subjectIndex,
  goalIndex,
  unitIndex,
}) => {
  return (
    <td
      style={{
        fontWeight: "600",
        fontSize: 16,
        textAlign: "center",
        paddingTop: "5px",
        border: "2px solid #1a8cff",
        cursor: "pointer",
        maxWidth: 20,
      }}
    >
      <span> {currentValue === +number && text} </span>
    </td>
  );
};

export default NoteR;
