/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API } from "../constants/env";
import {
  Container,
  CantineItem,
  CantineName,
  CantineText,
  CantineElem,
  CantineList,
  CantineLi,
} from "../styles/Cantine";
const Cantine = () => {
  const [token, settoken] = useState(null);
  const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];
  const [items, setitems] = useState(null);
  const Colors = [
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#f39c12",
    "#d35400",
    "#e74c3c",
  ];
  useEffect(() => {
    settoken(localStorage.getItem("token"));
  }, []);
  useEffect(() => {
    if (token) {
      console.log("TOKEN ", token);
      fetch(API.menu.all, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            console.log(response);
            swal("Error", response.message, "error");
          }
          response = response.map(({ _id, day, meal }) => {
            return { _id, day, meal };
          });
          console.log("response=>", response);
          setitems(response);
          //setdataIsLoading(false);
        })
        .catch((e) => {
          if (e.message === "Failed to fetch") {
            swal("Erreur", "Probléme de connection", "error");
          }
        });
    }
  }, [token]);
  const listOfItems = () => {
    if (items) {
      return items.map(({ _id, day, meal }) => {
        return (
          <CantineItem key={_id}>
            <CantineName BgColor={Colors[day]}>
              <CantineText>{days[day]}</CantineText>
            </CantineName>
            <CantineElem>
              <CantineList>
                {meal.map((item) => {
                  return <CantineLi>{item}</CantineLi>;
                })}
              </CantineList>
            </CantineElem>
          </CantineItem>
        );
      });
    } else {
      return null;
    }
  };
  return <Container>{listOfItems()}</Container>;
};
export default Cantine;
