import styled from "styled-components";

const Emploibg = styled.div`
  width: 98%;
  box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.8);
  margin: 5px auto;
  background-color: rgba(255, 255, 255, 0.85);
  height: 100%;
  padding:9px;
`;

const Column = styled.div`
  position: relative;
  float: left;
  margin: 0 auto;
  height: 100%;
  width: 19.5%;
  margin-right: ${(props) => (props.margin ? " 1px" : "12px")};
  @media (max-width: 600px) {
    width: 100%;
    margin: 20px 10px;
    height: auto;
  }
`;

const Day = styled.div`
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  color: gray;
  font-size: 18px;
  padding: 30px 0px;

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const EmploiBoxes = styled.div`
  padding: 2px;
  margin: 0 auto;
  height: auto;
  margin-top: 30px;

  @media (max-width: 600px) {
    padding: 0px 15px;
    margin-top: 10px;
  }
`;

const EmploiBox = styled.div`
  padding: 8px 5px;
  margin-bottom: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.8);
  transition: transform 0.5s;
  &:hover {
    transform: translateY(-13px);
  }

  @media (max-width: 600px) {
    width: 30%;
    float: left;
    margin-right: 10px;
  }
`;

const Title = styled.div`
  padding: 6px;
  float: left;
  color: White;
  font-size: 16px;
  border-radius: 6px;
  background-color: ${(props) => (props.Color ? props.Color : "#4358d1")};;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;

  @media (max-width: 600px) {
    padding: 2px;
    font-size: 14x;
  }
`;

const Description = styled.div`
  text-align: center;
  color: grey;
  font-weight: 300;
  font-size: 13px;
  margin-left: 2px;

  @media (max-width: 600px) {
    font-size: 11px;
  }
`;

const Matiere = styled.div``;

const Time = styled.div`
  font-size: 13px;

  @media (max-width: 600px) {
    font-size: 11px;
  }
`;

export {
  Emploibg,
  Column,
  Day,
  EmploiBoxes,
  EmploiBox,
  Title,
  Description,
  Matiere,
  Time,
};
