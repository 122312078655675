// import Student from "../pages/Emploi/Student";
// import Teacher from "../pages/Emploi/Teacher";
// import ListUsers from "../pages/Users/ListUsers";
// import AddUsers from "../pages/Users/AddUser";
// import EditUsers from "../pages/Users/EditUser";

// import ListClasses from "../pages/Classes/ListClasses";
// import AddClasses from "../pages/Classes/AddClasse";
// import EditClasses from "../pages/Classes/EditClasse";

// import ListHomework from "../pages/Homeworks/ListHomework";
// import AddHomework from "../pages/Homeworks/AddHomework";
// import EditHomework from "../pages/Homeworks/EditHomework";

import HomeScreen from "../components/Home";
import ResultScreen from "../components/Result";
import CantineScreen from "../components/Cantine";
import ExamenScreen from "../components/Examen";
import EmploiScreen from "../components/Emploi";
import PaymentScreen from "../components/Payment";
import Homework from "../components/Homework";
import Calender from "../components/Calender";
import Elearning from "../components/Elearning";
import Logout from "../components/Logout";
//import NotFound from "../components/NotFound/NotFound";
import Template from "../libs/Template";
import NotFound from "../libs/NotFound";
import withAuth from "../libs/withAuth";
import { Notification } from "../components/Notification";

export const routes = [
  {
    special: true,
    path: "/",
    exact: true,
    main: withAuth(HomeScreen),
  },
  // ==========> Acceuil
  {
    special: true,
    path: "/home",
    exact: true,
    main: withAuth(HomeScreen),
  },
  {
    path: "/cantine",
    exact: true,
    main: withAuth(Template(CantineScreen, "Cantine", "#f43b3b")),
  },
  {
    path: "/result",
    exact: true,
    main: withAuth(Template(ResultScreen, "Résultat", "#4358d1")),
  },
  {
    path: "/examen",
    exact: true,
    main: withAuth(Template(ExamenScreen, "Examens", "#2f94e4")),
  },
  {
    path: "/payment",
    exact: true,
    main: withAuth(Template(PaymentScreen, "Paiement", "#fae000")),
  },

  {
    // ========> Emploi
    path: "/emploi",
    exact: true,
    main: withAuth(Template(EmploiScreen, "Emploi", "#fabc00")),
  },

  // ==========> HomeWork
  {
    path: "/homeworks",
    exact: true,
    main: withAuth(Template(Homework, "Homeworks", "#74db52")),
  },

  // ==========> Calander
  {
    path: "/calender",
    exact: true,
    main: withAuth(Template(Calender, "Calendrier", "#f4006e")),
  },

  // ==========> Calander
  {
    path: "/elearning",
    exact: true,
    main: withAuth(Template(Elearning, "E-learning", "#d100c6")),
  },

  {
    path: "/notifications",
    exact: true,
    main: withAuth(Template(Notification, "Notifications", "#d100c6")),
  },

  // ======>logout

  {
    path: "/logout",
    exact: true,
    main: Logout,
  },
  {
    path: "/404",
    main: NotFound,
  },
];
