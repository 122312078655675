import React, { Component } from "react";
import swal from "sweetalert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import { API, ROUTER } from "../constants/env";
export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }
    componentWillMount() {
      console.log("d5al");
      fetch(API.me, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            //localStorage.clear();
            return this.setState({ loading: false, redirect: true });
          }
          console.log("response is => ", response);
          localStorage.setItem("user", JSON.stringify(response));
          return this.setState({ loading: false });
        })
        .catch((err) => {
          swal("Erreur", "Probléme de connection", "error");
          //localStorage.clear();
          setTimeout(() => {
            this.setState({ loading: false, redirect: false });
          }, 2000);
          
          //window.location = ROUTER.LOGIN;
        });
    }
    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return (
          <div style={{
            display:"flex",
            height:"100vh",
            justifyContent:"center",
            alignItems:"center",
            alignSelf:"center",
            backgroundColor:"#ffffff"
          }}>
            <Loader
              type="Puff"
              color="#f1c40f"
              height={200}
              width={200}
              timeout={5000} //3 secs
            />
          </div>
        );
      }
      if (redirect) {
        return <Redirect to={ROUTER.LOGIN} />;
      }
      return <ComponentToProtect {...this.props} />;
    }
  };
}
