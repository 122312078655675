import styled from "styled-components";

const Container = styled.div`
  display:flex;
  flex:1;
  height:100vh;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  align-self:center;
  background-color:#2ed573;
`;
const Title = styled.p`
  font-size:75px;
  font-weight:bolder;
  color:white;
`;
const SubTitle = styled.p`
  font-size:35px;
  font-weight:bolder;
`;
export {
  Container,
  Title,
  SubTitle,
};