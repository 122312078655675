import styled from "styled-components";

const Container = styled.div`
  display:flex;
  flex:1;
  height:100vh;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  align-self:center;
  background-color:#e9d309;
`;
const Title = styled.img`
`;
export {
  Container,
  Title,
};