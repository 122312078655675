import React from "react";
import Result from "../public/Icons/result.png";
import Calendrier from "../public/Icons/calendrier.png";
import Emplois from "../public/Icons/emplois.png";
import Examens from "../public/Icons/examens.png";
import Elearning from "../public/Icons/elearning.png";
import Catine from "../public/Icons/cantine.png";
import Homework from "../public/Icons/homework.png";
import Paiement from "../public/Icons/paiement.png";
import Logout from "../public/Icons/exit.svg";

const navigationConfig = [
  {
    subMenu: false,
    name: "Notifications",
    icon: (
      <>
        <img style={{ width: 30 }} src={Result} />
      </>
    ),
    route: "/notifications",
  },
  {
    subMenu: false,
    name: "Résultats",
    icon: (
      <>
        <img style={{ width: 30 }} src={Result} />
      </>
    ),
    route: "/result",
  },
  {
    subMenu: false,
    name: "Calendrier",
    icon: (
      <>
        <img style={{ width: 30 }} src={Calendrier} />
      </>
    ),
    route: "/calender",
  },
  {
    subMenu: false,
    name: "Emplois",
    icon: (
      <>
        <img style={{ width: 30 }} src={Emplois} />
      </>
    ),
    route: "/emploi",
  },
  {
    subMenu: false,
    name: "Examens",
    icon: (
      <>
        <img style={{ width: 30 }} src={Examens} />
      </>
    ),
    route: "/examen",
  },
  {
    subMenu: false,
    name: "E-learning",
    icon: (
      <>
        <img style={{ width: 30 }} src={Elearning} />
      </>
    ),
    route: "/elearning",
  },
  {
    subMenu: false,
    name: "Cantine",
    icon: (
      <>
        <img style={{ width: 30 }} src={Catine} />
      </>
    ),
    route: "/cantine",
  },
  {
    subMenu: false,
    name: "Homework",
    icon: (
      <>
        <img style={{ width: 30 }} src={Homework} />
      </>
    ),
    route: "/homeworks",
  },
  {
    subMenu: false,
    name: "Paiement",
    icon: (
      <>
        <img style={{ width: 30 }} src={Paiement} />
      </>
    ),
    route: "/payment",
  },

  {
    subMenu: false,
    name: "Logout",
    icon: (
      <>
        <img style={{ width: 30, fill: "rgb(44,62,80)" }} src={Logout} />
      </>
    ),
    route: "/logout",
  },
];
export default navigationConfig;
