import React, { useEffect } from "react";
//import { useHistory } from "react-router";
import {ROUTER} from "../constants/env";
import LogoutImg from "../public/images/logout.gif";
import {
  Container,
  Title,
} from "../styles/Logout";
const Logout = () => {
  useEffect(() => {
    localStorage.clear();
    window.location = ROUTER.LOGIN;
  }, []);
  return (
    <Container>
      <img src={LogoutImg}/>
    </Container>
  );
};

export default Logout;
