import React from "react";

const Snote = ({ note, min, max, subjectIndex, unitInedx }) => {
  return (
    <td
      style={{
        border: "2px solid #0040ff",
        backgroundColor: "#ffffff",
        boxSizing: "border-box",
        padding: 1,
        display: "flex",
      }}
    >
      {note === 0 ? (
        <span
          style={{
            width: "33%",
            height: "100%",
            textAlign: "center",
            borderRadius: 0,
            fontSize: 17,
            fontWeight: 400,
          }}
        >
          Dispensé
        </span>
      ) : (
        <input
          className="form-control"
          disabled
          style={{
            width: "33%",
            height: "100%",
            textAlign: "center",
            borderRadius: 0,
            fontSize: 17,
            fontWeight: 700,
          }}
          value={note}
          defaultValue={note}
          step="0.01"
          type="number"
        />
      )}
      <input
        className="form-control"
        style={{
          flex: 1,
          height: "100%",
          textAlign: "center",
          borderRadius: 0,
          fontSize: 17,
          fontWeight: 700,
        }}
        placeholder="min"
        step="0.01"
        type="number"
        disabled
        value={min !== 0 && min}
      />
      <input
        className="form-control"
        style={{
          height: "100%",
          textAlign: "center",
          borderRadius: 0,
          fontSize: 17,
          fontWeight: 700,
          flex: 1,
        }}
        disabled
        step="0.01"
        type="number"
        placeholder="max"
        value={max !== 0 && max}
      />
    </td>
  );
};

export default Snote;
