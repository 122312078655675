import React from "react";

const RemaqueR = ({ index, remarque, Ar }) => {
  return (
    <td
      colSpan="5"
      style={{
        border: "2px solid #1a8cff",
        color: "#ffaa00",
        fontWeight: "bold",
        fontSize: 20,
        padding: 10,
      }}
    >
      <label
        style={{
          borderBottom: "2px solid #ffaa00",
          float: Ar ? "right" : "left",
        }}
      >
        {Ar ? `: توصيات المعلمة` : `Recommandataion de l'institutrice :`}
      </label>

      <textarea
        value={remarque}
        className="form-control"
        placeholder="................................."
        style={{
          minWidth: "100%",
          maxHeight: "150px",
          textAlign: Ar ? "right" : "left",
        }}
      />
    </td>
  );
};

export default RemaqueR;
