import React from "react";
import styled from "styled-components";
//import {Link} from 'react-router-dom';
import bg from "../public/images/ChosenBg.png";
const Container = styled.div`
  //background-color:red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ContainerHeader = styled.div`
  //margin:50px 25px;
  margin-horizontal: 25px;
  margin-vertical: 10px;
  display: flex;
  background-color: white;
  flex-direction: row;
  width: 50%;
  padding: 7px;
  margin-bottom: 2%;
  border-radius: 22px;
  justify-content: space-between;
  align-self: center;
`;
const BtnArrowLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  justify-content: center;
  cursor: pointer;
`;
const ArrowLeftImg = styled.img`
  width: 20px;
  height: 20px;
  align-self: center;
`;
const CenterText = styled.h4`
  font-size: 20px;
  align-self: center;
  color: gray;
`;
const BtnArrowRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  justify-content: center;
  cursor: pointer;
`;
const ArrowRightImg = styled.img`
  width: 20px;
  height: 20px;
  align-self: center;
`;
const Table = styled.div`
  display: flex;
  //flex:1;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin-horizontal: 25px;
  margin-vertical: 10px;
  background-color: white;
  flex-direction: row;
  padding: 7px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 22px;
`;
const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px #eaeaea;
  padding-vertical: 7px;
  padding-horizontal: 5px;
  flex-direction: row;
  border-radius: 50px;
  width: 98%;
  padding-left: 15px;
  justify-content: space-between;
`;
const HeadItem = styled.h5`
  align-self: center;
  width: 24%;
  padding: 4px;
  color: white;
`;
const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  border: 1px #eaeaea;
  padding-vertical: 7px;
  padding-horizontal: 5px;
  flex-direction: row;
  width: 98%;
  padding-left: 15px;
  justify-content: space-between;
`;
const BodyItem = styled.h5`
  align-self: center;
  width: 24%;
  padding: 4px;
  color: #8395a7;
`;
const ResContainer = styled.div`
  display: flex;
  background-color: white;
  margin: 0 20px;
  margin-top: 25px;
  padding: 12px;
  flex-direction: row;
  align-self: center;
  width: 40%;
  justify-content: space-around;
  border-radius: 14px;
`;
const ResItemBox = styled.div`
  background-color: #85c555;
  width: 30%;
  padding: 8px 10px;
  align-items: center;
  border-radius: 14px;
`;
const ResTitle = styled.h5`
  color: #eafce2;
  min-height: 40px;
  text-align: center;
  font-size: 15px;
`;
const ResValue = styled.h5`
  text-align: center;
  color: #eafce2;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 17px;
`;
export {
  Container,
  ContainerHeader,
  BtnArrowLeft,
  ArrowLeftImg,
  CenterText,
  BtnArrowRight,
  ArrowRightImg,
  Table,
  TableHeader,
  HeadItem,
  TableBody,
  BodyItem,
  ResContainer,
  ResItemBox,
  ResTitle,
  ResValue,
};
