const localApi = "http://localhost:5000/";
const serverApi = "https://api.ecole-odyssee.tn/";
const serverClient = "https://sms.ecole-odyssee.tn/assets/images/";
const baseURL = process.env.NODE_ENV == "development" ? localApi : serverApi;
const staticURL =
  process.env.NODE_ENV == "development" ? localApi : serverClient;

exports.baseURL = baseURL;
exports.ROUTER = {
  RESET: "/reset",
  LOGIN: "/login",
  HOME: "/home",

  USERS: {
    LIST: "/user",
    CREATE: "/user/new",
    EDIT: (id) => `/user/edit/${id}`,
  },

  HOMEWORKS: {
    LIST: "/homeworks",
    CREATE: "/homeworks/new",
    EDIT: (id) => `/homeworks/edit/${id}`,
  },

  ABSENCE: {
    LIST: "/absences",
    CREATE: "/absences/new",
    EDIT: (id) => `/absences/edit/${id}`,
  },

  ERROR: "/404",
};
exports.STATIC = {
  LOGO: "/assets/images/logo.png",
  LOGO_WHITE: `${staticURL}logoSideBar.png`,
  ICON: "/assets/images/icon.png",
  TITLE: "Brasserie 529",
  ROUTE: (file) =>
    `${staticURL}${file.startsWith("/") ? file.substring(1) : file}`,
};
exports.API = {
  auth: {
    login: `${baseURL}student-api/v1/auth/login`,
  },
  me: `${baseURL}student-api/v1/me/`,

  emplois: {
    all: (id) => `${baseURL}student-api/v1/schedule/list/${id}`,
  },
  menu: {
    all: `${baseURL}student-api/v1/menu/all`,
  },
  examen: {
    list: `${baseURL}student-api/v1/exam/list?page=1&count=200`,
  },
  resultat: {
    //"premier", "deuxieme", "troisieme"
    Student: (idStudent) =>
      ` ${baseURL}student-api/v1/reportCard/info/${idStudent}`,
    Prepa: (idPrepStudent) =>
      `${baseURL}student-api/v1/prepaReportCard/info/${idPrepStudent}`,
  },
  bill: {
    info: (id) => `${baseURL}student-api/v1/bill/info/${id}`,
  },

  calendar: {
    getRegister: (idStudent) =>
      `${baseURL}student-api/v1/register/all/${idStudent}`,
    getActions: (idClasse) => `${baseURL}student-api/v1/action/all/${idClasse}`,
    getActionType: `${baseURL}student-api/v1/actionType/all`,
  },

  elearning: {
    list: `${baseURL}student-api/v1/elearning/list?page=1&count=200`,
  },

  homework: {
    all: (classeID) => `${baseURL}student-api/v1/homework/all/${classeID}`,
  },

  classes: {
    all: `${baseURL}teacher-api/v1/classes/all`,
  },

  subjects: {
    getMySubjects: (id) => `${baseURL}teacher-api/v1/subjects/all/${id}`,
  },

  notifications: {
    getNotifications: `${baseURL}student-api/v1/notification/list`,
  },

  allAverge: {
    get: (id) => `${baseURL}student-api/v1/overallAverage/info/${id}`,
  },

  generatePdf: {
    student: (id) => `${baseURL}student-api/v1/reportCard/carnetPdf/${id}`,
    prepa: (id, tris) =>
      `${baseURL}student-api/v1/prepaReportCard/carnetPdf/${id}/${tris}`,
  },
};
