import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const Container = styled.div`
  display: flex;
  flex:1;
  height:auto;
  flex-direction: column;
  align-items:center;
`;
const AnnBox = styled.div`
  display:flex;
  width:450px;
  background-color:white;
  flex-direction:row;
  margin-bottom:10px;
  padding:10px;
  border-radius:10px;
`;
const IconDiv = styled.div`
  display:flex;
  align-self:center;
  align-items:center;
  justify-content:center;
  border-radius:10px;
  margin-right:10px;
  width:70px;
  height:60px;
  background-color:#ffce44;
`;
const TextDiv = styled.p`
  font-size:20px;
  color:white;
  margin:0;
`;
const DescriBox  = styled.div`
  padding: 0 7px;
  width:80%;
`;
const TitleFee = styled.h5`

`;
const DetailBox = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-top:10px;
  width:100%;
`;
const StatusElem = styled.p`
  background-color:${props => props.Color ? '#2ecc71':'#e74c3c' };
  padding:2px 7px;
  border-radius:8px;
  color:white;
`;
const PriceFee = styled.p`
  font-weight:bold;
`;

const TrimContainer = styled.div`
display:flex;
width:450px;
flex-direction:column;
background-color:white;
margin-bottom:20px;
border-radius:10px;
padding:8px;
margin-bottom:20px;
`;
const TrimTitle = styled.div`
  height:70px;
  display:flex;
  padding-bottom:10px;
  margin-bottom:10px;
  border-bottom:1px solid #7f8c8d;
`;
const TrimIcon = styled.div`
  width:60px;
  height:60px;
  background-color:#ffce44;
  display:flex;
  justify-content:center;
  align-items:center;
  align-self:center;
  margin-left:5px;
  border-radius:10px;
`;
const TrimIconTxt = styled.p`
  font-size:25px;
  color:white;
  margin:0;

`;
const TrimHeader = styled.div`
  display:flex;
  align-items:center;
  align-self:center;
  justify-content:center;
  width:80%;
`;
const TrimHeaderTxt = styled.p`
  margin:0;
  font-size:20px;
  color:#34495e;
`;
const ItemBox = styled.div`
  display:flex;
  justify-content:space-between;
`;
const ItemName = styled.h5`
  font-size:15px;
  font-weight:normal;
  color:gray;
  width:50%;
`;
const ItemPrice = styled.h5`
  font-size:15px;
  color:gray;
`;
const ItemStatus = styled.p`
  background-color:${props => props.Color ? '#2ecc71':'#e74c3c' };
  padding:2px 7px;
  border-radius:8px;
  color:white;
`;
const SubType = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
`;
const SubTypeTxt = styled.p`
  margin:0;
  background-color:#ffce44;
  width:70px;
  text-align:center;
  padding:2px 7px;
  border-radius:7px;
  color:white;
  margin-bottom:7px;
`;
export {
  Container,
  AnnBox,
  IconDiv,
  TextDiv,
  DescriBox,
  TitleFee,
  DetailBox,
  StatusElem,
  PriceFee,

  TrimContainer,
  TrimTitle,
  TrimIcon,
  TrimIconTxt,
  TrimHeader,
  TrimHeaderTxt,

  ItemBox,
  ItemName,
  ItemPrice,
  ItemStatus,

  SubType,
  SubTypeTxt,
};