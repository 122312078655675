import styled from "styled-components";

const ContainerN = styled.div`
  width: 98%;
  box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.8);
  margin: 5px auto;
  background-color: rgba(255, 255, 255, 0.85);
  height: auto;
  padding: 9px;
`;

const WarraperN = styled.div`
  width: 100%;
  box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.95);
  :not(:last-child) {
    margin-bottom: 20px;
  }
  background-color: white;
  padding: 20px;
`;

const Title = styled.h3`
  font-weight: 900;
`;

const SubTitle = styled.h6`
  font-weight: 400;
  color: gray;
`;
const NotParagraph = styled.p``;

const Date = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: gray;
  margin-left: 5px;
`;

export { ContainerN, WarraperN, Title, SubTitle, NotParagraph, Date };
