/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import {
  Container,
  Title,
  SubTitle,
} from "../styles/NotFound";
const NotFound =()=>{
  return (
    <Container>
      <Title>Page Not Found</Title>
      <SubTitle>404</SubTitle>
    </Container>
  )
}
export default NotFound;