import styled from "styled-components";
const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98%;
  margin: 0 auto;
  padding: 25px;
  flex-wrap: wrap;
`;

const HomeworkCard = styled.div`
  flex: 0 0 32%;
  box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.8);
  padding: 10px;
  display: flex;
  height: 100px;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin: 0px 5px 10px 0px;
  // transform: translateY(0px);
  cursor: pointer;
  transition: transform 0.4s;
  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 600px) {
    flex: 0 0 48%;
  }
`;

const SubjectTitle = styled.h3`
  flex: 0 0 20%;
  padding: 1px 2px;
  text-align: center;
  background-color: cyan;
  align-self: stretch;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubjectDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2px;
`;
const DescriptionTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: goldenrod;
`;

const DescriptionTodo = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: gey;
  margin-bottom: 0px;
  @media (max-width: 600px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

const DescriptionDate = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: gray;

  align-self: flex-end;
  @media (max-width: 600px) {
    font-size: 13px;
    font-weight: 600;
  }
`;

export {
  Container,
  HomeworkCard,
  SubjectTitle,
  SubjectDescription,
  DescriptionTitle,
  DescriptionTodo,
  DescriptionDate,
};
