/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import {Link} from 'react-router-dom';
import navigationConfig from "../../constants/navigationConfig";
import {
  Container,
  LogoBox,
  LogoImg,
  Submenus,
  ListOfItems,
  Item,
  ItemLink,
  ItemIconBox,
  ItemTitle,
  FooterBox,
  FooterImg,
} from "../../styles/Panel";
import Logo from "../../public/images/logo.png";
import QRCode from "../../public/images/qrcode.png";
const Panel =()=>{
  return (
    <Container>
      <LogoBox to="/home">
        <LogoImg src={Logo}/>
      </LogoBox>
      <Submenus>
        <ListOfItems>
          {
            navigationConfig.map((elem,index)=>{
              return (
                <Item>
                  <ItemLink to={elem.route}>
                    <ItemIconBox>
                      {elem.icon}
                    </ItemIconBox>
                    <ItemTitle>{elem.name}</ItemTitle>
                  </ItemLink>
                </Item>
              );
            })
          }
        </ListOfItems>
      </Submenus>
      <FooterBox>
        <FooterImg src={QRCode}/>
      </FooterBox>
    </Container>
  )
}
export default Panel;