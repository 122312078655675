/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component,useState,useEffect} from "react";
import swal from "sweetalert";
import {
  Container,
  HeaderSelector,
  ArrowToLeft,
  ArrowToRight,
  TheTitle,
  CantineItem,
  ElemBox,
  Box1,
  SubTitle,
  Box2,
  ElemTitle,
  ElemBody,
} from "../styles/Examen";
import ArrowLeft from "../public/images/ArrowLeft.png";
import ArrowRight from "../public/images/ArrowRight.png";
import {API} from "../constants/env";
const Cantine =()=>{
  const [user, setuser] = useState(null);
  const [token, settoken] = useState(null);
  const [Data, setData] = useState(null);
  const [Trimestre, setTrimestre] = useState(0);
  const [days, setdays] = useState(["Lundi","Mardi","Mercredi","Jeudi","Vendredi"]);
  const [colors, setcolors] = useState([
        '#fabc00','#4358d1','#f4006e','#d100c6','#f43b3b','#74db52'
    ])
  const Period = ["1er Trimestre","2eme Trimestre","3eme Trimestre"];
  const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }
  useEffect(() => {
    settoken(localStorage.getItem("token"));
    setuser(JSON.parse(localStorage.getItem("user")));
  }, []);
  useEffect(()=>{
    if(user&&token){
      fetch(API.examen.list, {
        method: "GET",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "x-auth-token":token
        },
        })
        .then((res) => res.json())
        .then((response) => {
            if (response.error) {
                console.log(response);
                swal("Error", response.message, "error");
            }
        console.log("List Of Exams ==>>>>>\n",response)
        let listOfExams = [
            {
                period:"1 er Trimestre",
                examens:[],
            },
            {
                period:"2 eme Trimestre",
                examens:[],
            },
            {
                period:"3eme Trimestre",
                examens:[],
            }
        ]
        let i =0,Title,iconText,spD,periodDate;
        response.data.forEach((elem) => {
            console.log("inside forEach")
            Title = elem.subject.name;
            iconText = elem.subject.name.slice(0, 2).toUpperCase();
            let specificDate;
            if(elem.semester.includes("1")){
                console.log("inside 1st trimestre",elem.subject)
                if(elem.date){
                    // a specific date
                    spD = new Date(elem.date);
                    let dateString = `${days[spD.getDay()]} ${spD.getDate()} ${months[spD.getMonth()]} ${spD.getFullYear()} ${elem.startHour}-${elem.endHour}`;
                    listOfExams[0].examens.push({
                        color:colors[i],
                        Title:Title,
                        iconText:iconText,
                        LittleBoxText:dateString,
                    })
                }else{
                    // for a period of time
                    let date1= new Date(elem.startDate);
                    let formatDate1 = `${days[date1.getDay()]} ${date1.getDate()} ${months[date1.getMonth()]} ${date1.getFullYear()}`;
                    let date2= new Date(elem.endDate);
                    let formatDate2 = `${days[date2.getDay()]} ${date2.getDate()} ${months[date2.getMonth()]} ${date2.getFullYear()}`;
                    periodDate = `${formatDate1} - ${formatDate2}`
                    listOfExams[0].examens.push({
                        color:colors[i],
                        Title:Title,
                        iconText:iconText,
                        LittleBoxText:periodDate,
                    })
                }
            }else if(elem.semester.includes("2")){
                console.log("inside 2nd trimestre")
                if(elem.date){
                    // a specific date
                    specificDate = formatDate(new Date(elem.date));
                    listOfExams[1].examens.push({
                        color:colors[i],
                        Title:Title,
                        iconText:iconText,
                        LittleBoxText:`${specificDate} ${elem.startHour}-${elem.endHour}`,
                    })
                }else{
                    periodDate = `${formatDate(new Date(elem.startDate))} => ${formatDate(new Date(elem.endDate))}`
                    listOfExams[1].examens.push({
                        color:colors[i],
                        Title:Title,
                        iconText:iconText,
                        LittleBoxText:periodDate,
                    })
                }
            }else if(elem.semester.includes("3")){
                console.log("inside 3rd trimestre")
                if(elem.date){
                    specificDate = formatDate(new Date(elem.date));
                    listOfExams[2].examens.push({
                        color:colors[i],
                        Title:Title,
                        iconText:iconText,
                        LittleBoxText:`${specificDate} ${elem.startHour}-${elem.endHour}`,
                    })
                }else{
                    periodDate = `${formatDate(new Date(elem.startDate))} => ${formatDate(new Date(elem.endDate))}`
                    listOfExams[2].examens.push({
                        color:colors[i],
                        Title:Title,
                        iconText:iconText,
                        LittleBoxText:periodDate,
                    })
                }
            }
            i=(i+1)%6;
        });
        console.log("((((((((( List Of Exams ))))))))))))\n",listOfExams);
        setData(listOfExams);
        })
        .catch((e) => {
          if (e.message === "Failed to fetch") {
            swal("Erreur", "Probléme de connection", "error");
          }
        });
    }
  }, [user]);
  const months  = ["janv.","févr.","mars","avr.","mai","juin","juil.","août","sept.","oct.","nov.","déc."];
  const nextFunc = (number)=>{
        console.log("Clicked");
        if(number > 0 ){
            setTrimestre(Math.abs((Trimestre+number)%3));
        }else{
            setTrimestre((Trimestre-number+1)%3);
        }
    }
  const renderItems = ()=>{
    return (Data ? Data[Trimestre]?.examens.map((exam,index)=>{
          return (
            <ElemBox>
              <Box1 Color={colors[index%5]}>
                <SubTitle>{exam.Title.substring(0,2).toUpperCase()}</SubTitle>
              </Box1>
              <Box2>
                <ElemTitle  Color={colors[index%5]}>{exam.Title}</ElemTitle>
                <ElemBody>{exam.LittleBoxText}</ElemBody>
              </Box2>
            </ElemBox>
          )
        }):null)
  }
  return (
    <Container>
      <HeaderSelector>
        <ArrowToLeft src={ArrowLeft}  onClick={()=>{nextFunc(-1)}}/>
        <TheTitle>{Period[Trimestre]}</TheTitle>
        <ArrowToRight src={ArrowRight} onClick={()=>{nextFunc(1)}}/>
      </HeaderSelector>
      <CantineItem>
        {renderItems()}
      </CantineItem>
    </Container>
  );
}
export default Cantine;