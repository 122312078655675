import React from "react";
import Pdf from "../../Pdf/Pdf";
import CarnetSTable from "./CarnetSTable";
import Smoyenne from "./Smoyenne";
import SmoyenneG from "./SmoyenneG";

const CarnetS = ({ carnet, pageNumber }) => {
  console.log(carnet);
  return (
    <div style={{ width: "100%", height: "auto" }}>
      {carnet?.units?.map((unit, index) => (
        <React.Fragment key={index}>
          <CarnetSTable unit={unit} unitInedx={index} />
          <br />
        </React.Fragment>
      ))}
      <Smoyenne
        general
        color="#b87006"
        moyenne={carnet?.moyenne}
        fontSizeTitle="28px"
        title="Moyenne du trismestre"
        fontSizeMoyenne="50px"
        remarque={carnet?.remarque}
      />
      {pageNumber === 2 && (
        <SmoyenneG
          studentId={carnet?.student?._id}
          color="#b87006"
          fontSizeTitle="18px"
          title="Moyenne général"
          fontSizeMoyenne="20px"
        />
      )}
    </div>
  );
};

export default CarnetS;
