import moment from "moment";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API } from "../constants/env";
import ArrowLeft from "../public/images/ArrowLeft.png";
import ArrowRight from "../public/images/ArrowRight.png";
import {
  Container,
  HomeworkCard,
  SubjectTitle,
  SubjectDescription,
  DescriptionTitle,
  DescriptionTodo,
  DescriptionDate,
} from "../styles/Homeworks";
import {
  ContainerHeader,
  BtnArrowLeft,
  ArrowLeftImg,
  CenterText,
  BtnArrowRight,
  ArrowRightImg,
} from "../styles/Result";

const Homework = () => {
  const [pageNumber, setpageNumber] = useState(new Date().getMonth());
  const [works, setWorks] = useState([]);
  const Months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  useEffect(() => {
    fetch(
      API.homework.all(JSON.parse(localStorage.getItem("user")).classe._id),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          // console.log(response);
          return swal("Error", response.message, "error");
        }

        setWorks(response);
      })
      .catch((e) => {
        if (e.message === "Failed to fetch") {
          swal("Erreur", "Probléme de connection", "error");
        }
      });
  }, []);

  const getWorks = () => {
    console.log(works);
    return works.filter(
      (work) => new Date(work?.deadline).getMonth() === pageNumber
    );
  };

  const nextFunc = (number) => {
    //console.log("Clicked");
    if (number > 0) {
      setpageNumber(Math.abs((pageNumber + number) % 12));
    } else {
      setpageNumber((pageNumber - number + 10) % 12);
    }
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ContainerHeader>
          <BtnArrowLeft onClick={() => nextFunc(-1)}>
            <ArrowLeftImg src={ArrowLeft} />
          </BtnArrowLeft>
          <CenterText>{`${Months[pageNumber]}`}</CenterText>
          <BtnArrowRight onClick={() => nextFunc(1)}>
            <ArrowRightImg src={ArrowRight} />
          </BtnArrowRight>
        </ContainerHeader>
      </div>
      <Container>
        {getWorks().length !== 0 ? (
          getWorks().map(({ deadline, title, description, subject, _id }) => (
            <HomeworkCard key={_id}>
              <SubjectTitle>
                {subject.name.slice(0, 2).toUpperCase()}
              </SubjectTitle>
              <SubjectDescription>
                <DescriptionTitle>{subject.name}</DescriptionTitle>
                <DescriptionTodo>{title}</DescriptionTodo>
                <DescriptionTodo>{description}</DescriptionTodo>
                <DescriptionDate>
                  {moment(deadline).format("l")}
                </DescriptionDate>
              </SubjectDescription>
            </HomeworkCard>
          ))
        ) : (
          <div
            style={{
              padding: 10,
              fontSize: 18,
              fontWeight: 600,
              margin: "0 auto",
            }}
          >
            Pas de homeWork
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Homework;
