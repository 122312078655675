import styled from "styled-components";
import bg from "../public/images/ChosenBg.png";
import { Link } from "react-router-dom";
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background-image: url(${bg});
  background-size: cover;
  background-attachment: fixed;
`;
const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 17%;
  @media (max-width: 600px) {
    margin: 0px;
  }
`;
const Header = styled.div`
  height: auto;
  //background-color:goldenrod;
  @media (max-width: 600px) {
  }
`;
const AccountInfo = styled.div`
  //background-color: rosybrown;
  height: auto;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 800px) {
    justify-content: center;
    width: 100%;
  }
`;
const DesciptionBox = styled.div`
  background-color: white;
  display: inline-block;
  align-self: flex-end;
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 40%;
  margin-right: 2%;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 7px 6px 15px -8px rgba(0, 0, 0, 0.8);
  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0%;
    border-radius: 0px 0px 6px;
  }
`;
const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border: 1px solid black;
  border-radius: 25px;
`;
const InfoBox = styled.div`
  margin-left: 20px;
  text-align: center;
`;
const Name = styled.h5`
  @media (max-width: 600px) {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;
const Classe = styled.h6``;
const TitleBox = styled.div`
  margin: 0 auto;
  width: 80%;
  margin-top: 15px;
  background-color: ${(props) => (props.Color ? props.Color : "#2980b9")};
  padding: 15px 0;
  border-radius: 11px;
  text-align: center;

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
  }
`;
const Title = styled.h3`
  // display: inline-block;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: bold;
`;
const ContentBox = styled.div`
  /*display: flex;
  flex: 1;
  justify-content: center;*/
  margin-top: 15px;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;

const Content = styled.div`
  background-color:white;
  display: flex;
  flex-wrap: wrap;
  flex: 0;
  margin: 0 auto;
  padding:7px;
  border-radius: 10px;
  width: 40%;
  flex-direction: column;
  display:none;
  @media (max-width: 600px) {
    display: flex;
  }
`;
const BtnBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: ${(props) => (props.color ? props.color : "green")};
  margin-top:4px;
  padding:7px 0;
  border-radius: 10px;
`;
const BtnLink = styled(Link)`
  color: white;
  &:hover {
    color: white;
  }
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;
const BtnTitle = styled.h4``;
export {
  Container,
  BodyContainer,
  Header,
  AccountInfo,
  DesciptionBox,
  Avatar,
  InfoBox,
  Name,
  Classe,
  TitleBox,
  Title,
  ContentBox,
  Content,
  BtnLink,
  BtnBox,
  BtnTitle,
};
