import moment from "moment";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API, baseURL } from "../constants/env";
import {
  ContainerN,
  WarraperN,
  Title,
  SubTitle,
  NotParagraph,
  Date,
} from "../styles/Notification";
// import baseURL from "../constants/env"
export const Notification = () => {
  const [notifications, setNotifications] = useState([]);

  const handleDownloadClick = (filename) => {
    // this.setState({ downloading: true });
    const imageUrl = baseURL + filename;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  useEffect(() => {
    fetch(API.notifications.getNotifications, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }
        console.log("fetching ......");
        setNotifications(response.data);
      })
      .catch((e) => {
        if (e.message === "Failed to fetch") {
          swal("Erreur", "Probléme de connection", "error");
        }
      });
  }, []);

  return (
    <ContainerN>
      {notifications.length !== 0 ? (
        notifications.map((notif) => (
          <WarraperN>
            <Title>
              {notif.title} <Date>({notif.date})</Date>
            </Title>
            <SubTitle>{notif.subTitle}</SubTitle>
            <NotParagraph>{notif.body}</NotParagraph>
            {notif?.file?.length > 0 ? (
              <button
                type="button"
                className="btn "
                onClick={() => handleDownloadClick(notif?.file[0])}
              >
                <Title>Download</Title>
              </button>
            ) : // <a href={baseURL + notif?.file[0]} target={"_blank"}>
            //   <Title>Download</Title>
            // </a>
            null}
          </WarraperN>
        ))
      ) : (
        <h1>no notifications</h1>
      )}
    </ContainerN>
  );
};
