import styled from "styled-components";

const Container = styled.div`
  //background-color: rgba(255, 255, 255, 0.85);
  background-color: rgba(244, 0, 110, 0.75);
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom:25px;
  padding: 25px;
  border-radius: 25px 0;
`;

const ActionContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  margin-bottom: 15px;
  border-radius: 25px 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ActionTitle = styled.h6`
  font-size: 18px;
  font-weight: 600;
`;

const ActionList = styled.div`
  margin-top: 1px;
  width: 100%;
  display: flex;
  flex:1;
  flex-wrap:wrap;
  align-items: center;
  justify-content:center;
  align-self:center;
`;

const ActionItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content:space-between;
  align-self:center;
  min-width:41%;
  max-width:45%;
  height:25%;
  border:1px solid rgba(244, 0, 110, 0.75);
  //box-shadow: 10px 10px 72px -20px rgba(244, 0, 110, 0.75);
  padding: 5px;
  margin: 7px;
  margin-top:15px;
  border-radius:8px;
  //background-color:red;
  @media (max-width: 600px) {
    flex: 0 0 48%;
    margin: 0px 10px 15px 0px;
  }
  &:hover{
    background-color:#ced6e0;
  }
`;

const ItemTitle = styled.div`
  display:flex;
  flex:1;
  align-items:center;
  align-self:center;
  justify-content:center;
  background-color: ${(props) => props.color};
  padding: 7px;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: 18px;
  max-width:15%;
  @media (max-width: 900px) {
    font-size: 13px;
    border-radius: 6px;
  }
`;

const ItemDescription = styled.div`
  width:85%;
  display:flex;
  flex-direction:column;
  margin-left:6px;
`;

const ItemHeader = styled.span`
  font-size: 15px;
  font-weight: 500;
  @media (max-width: 900px) {
    font-size: 11px;
  }
`;

const ItemDate = styled.span`
  display:flex;
  flex:1;
  justify-content:flex-end;
  font-size: 13px;
  color: #2c3e50;
  @media (max-width: 900px) {
    font-size: 9px;
  }
`;

export {
  Container,
  ActionContainer,
  ActionTitle,
  ActionList,
  ActionItem,
  ItemTitle,
  ItemDescription,
  ItemHeader,
  ItemDate,
};
