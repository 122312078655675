import React from "react";
import styled from "styled-components";
import bg from "../public/images/ChosenBg.png";
const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-around;
  background-image: url(${bg});

  background-size: cover;
`;
const LoginBox = styled.div`
  align-self: center;
  width: 30%;
  border-radius: 7px;
  padding: 1%1%;
  justify-content: center;

  @media (max-width: 600px) {
    width: 80%;
  }
`;
const LogoImg = styled.img`
  display: block;
  margin: 0 auto;
  height: 18vh;
  width: auto;
  margin-bottom: 20px;
  //background-color:rgba(255,255,255,0.2);
`;
const FormBox = styled.form`
  padding: 35px 30px;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 9px;
  -webkit-box-shadow: -3px 6px 11px -2px rgba(0, 0, 0, 0.51);
  box-shadow: -3px 6px 11px -2px rgba(0, 0, 0, 0.51);
`;
const SubmitBtn = styled.button`
  font-weight: 900;
  margin: 0 auto;
`;
export { Container, LoginBox, LogoImg, FormBox, SubmitBtn };
