/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { API, ROUTER, STATIC } from "../constants/env";
import Logo from "../public/images/logo.png";
import {
  Container,
  LoginBox,
  LogoImg,
  FormBox,
  SubmitBtn,
} from "../styles/Login";
const Login = () => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    fetch(API.auth.login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: data.username,
        password: data.password,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }
        localStorage.setItem("token", response.token);
        localStorage.setItem("user", JSON.stringify(response));
        window.location = ROUTER.HOME;
      })
      .catch((e) => {
        if (e.message === "Failed to fetch") {
          swal("Erreur", "Probléme de connection", "error");
        }
        localStorage.clear();
      });
  };
  return (
    <Container>
      <LoginBox>
        <LogoImg src={Logo} />
        <FormBox className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <input
              className="form-control h-auto py-5 px-6"
              type="text"
              placeholder="Nom d'utilisateur"
              name="username"
              ref={register({ required: true })}
            />
            {errors.username && (
              <div>
                <span className="text-danger">username required</span>
              </div>
            )}
          </div>
          <div className="form-group">
            <input
              className="form-control h-auto py-5 px-6"
              type="password"
              placeholder="Mot de passe"
              name="password"
              ref={register({ required: true })}
            />
            {errors.password && (
              <span className="text-danger">password required</span>
            )}
          </div>
          <div className="form-group d-flex flex-wrap  align-items-center">
            {/* <div
              className="text-dark-50 text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            ></div> */}
            <SubmitBtn
              type="submit"
              className="btn btn-primary font-weight-bold px-10 py-4"
            >
              SE CONNECTER
            </SubmitBtn>
          </div>
        </FormBox>
      </LoginBox>
    </Container>
  );
};
export default Login;
