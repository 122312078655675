import React from "react";
import NotesMark from "./NotesMark";
import RemaqueR from "./RemarqueR";
import SubjectR from "./SubjectR";
import TableElement from "./TableElement";
import Thead from "./Thead";

const CarnetTable = ({ unit, Ar, index: unitIndex }) => {
  return (
    <div className="card ">
      <div className="card-header">
        <h1 className="font-weight-bold text-center"> {unit.unitName}</h1>
      </div>
      <div className="card-body">
        <table className="table" style={{ border: "2px solid #1a8cff" }}>
          <thead>
            <tr>
              <Thead title={Ar ? "المواد" : "les Matiéres"} per="20" Ar={Ar} />
              <Thead
                Ar={Ar}
                title={
                  Ar
                    ? " : المتعلم قادر على أن"
                    : "Votre enfant est capable de :"
                }
                per="60"
              />
              <Thead
                Ar={Ar}
                title={Ar ? "معيار التملك" : "les acquis"}
                colspan="3"
                per="20"
              />
            </tr>
          </thead>
          <tbody>
            {unit?.subjects.map((subject, index) => {
              const subjectIndex = index;
              return subject?.goals.map((goal, index) => (
                <tr
                  key={index}
                  style={{
                    borderBottom:
                      index === subject.goals.length - 1 && "2px solid #1a8cff",
                    zIndex:
                      index === subject.goals.length - 1 && index !== 0 && 2,
                  }}
                >
                  {index === 0 && (
                    <SubjectR
                      text={subject.name}
                      rowSpan={subject.goals.length}
                    />
                  )}
                  <TableElement text={goal} />
                  <NotesMark
                    note={subject.note[index]}
                    subjectIndex={subjectIndex}
                    goalIndex={index}
                    unitIndex={unitIndex}
                  />
                </tr>
              ));
            })}

            <tr>
              <RemaqueR Ar={Ar} index={unitIndex} />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CarnetTable;
