/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState,useEffect} from "react";
import swal from "sweetalert";
import {API ,}from "../constants/env";
import {
  Container,
  AnnBox,
  IconDiv,
  TextDiv,
  DescriBox,
  TitleFee,
  DetailBox,
  StatusElem,
  PriceFee,
  TrimContainer,
  TrimTitle,
  TrimIcon,
  TrimIconTxt,
  TrimHeader,
  TrimHeaderTxt,
  ItemBox,
  ItemName,
  ItemPrice,
  ItemStatus,
  SubType,
  SubTypeTxt,
} from "../styles/Payment";
const Payment =()=>{
  const [user, setuser] = useState(null);
  const [token, settoken] = useState(null);
  const [annuelPay, setannuelPay] = useState(null);
  const [trim1, settrim1] = useState(null);
  const [trim2, settrim2] = useState(null);
  const [trim3, settrim3] = useState(null);
  //const [Data, setData] = useState(null);
  useEffect(() => {
    settoken(localStorage.getItem("token"));
    setuser(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(()=>{
    if(user&&token){
      fetch(API.bill.info(user._id), {
        method: "GET",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "x-auth-token":token
        },
        })
        .then((res) => res.json())
        .then((response) => {
            if (response.error) {
                console.log(response);
                swal("Error", response.message, "error");
            }
        console.log("List Of Exams ==>>>>>\n",response)
        let i =0,Title,iconText,spD,periodDate;
        response.forEach((elem) => {
          switch (elem.period) {
            case "Annuel":
              setannuelPay(elem.Fees.filter(x => !!x));
              break;
            case "1er Trimestre":
              settrim1([
                elem.Fees.filter(x => !!x),
                elem.Clubs.filter(x => !!x),
                elem.Services.filter(x => !!x)
              ]);
              break;
            case "2ème Trimestre":
              settrim2([
                elem.Fees.filter(x => !!x),
                elem.Clubs.filter(x => !!x),
                elem.Services.filter(x => !!x)
              ]);
              break;
            case "3ème Trimestre":
              settrim3([
                elem.Fees.filter(x => !!x),
                elem.Clubs.filter(x => !!x),
                elem.Services.filter(x => !!x)
              ]);
              break;
            default:
              break;
          }
        });
        //console.log("((((((((( List Of Exams ))))))))))))\n",listOfExams);
        //setData(listOfExams);
        })
        .catch((e) => {
          if (e.message === "Failed to fetch") {
            swal("Erreur", "Probléme de connection", "error");
          }
        });
    }
  }, [user]);
  const renderAnnuelPay= ()=>{
    return (annuelPay?.map(({name,price,period,status})=>{
        return (
            <AnnBox>
              <IconDiv>
                <TextDiv>{name.substring(0,2).toUpperCase()}</TextDiv>
              </IconDiv>
              <DescriBox>
                <TitleFee>{name}</TitleFee>
                <DetailBox>
                  <PriceFee>{price}</PriceFee>
                  {status ?
                  <StatusElem Color={status}>Payé</StatusElem>
                  :
                  <StatusElem Color={status}>Non payé</StatusElem>
                  }
                  
                </DetailBox>
              </DescriBox>
            </AnnBox>
          )
        })
    )
  }
  const renderTrimPay=(trim,title)=>{
    if(trim){
        return (
          <TrimContainer>
            <TrimTitle>
              <TrimIcon>
                <TrimIconTxt>{title.substring(0,1)}</TrimIconTxt>
              </TrimIcon>
              <TrimHeader>
                <TrimHeaderTxt>{title}</TrimHeaderTxt>
              </TrimHeader>
            </TrimTitle>
            {trim[0]?.map(({name,price,period,status})=>{
              return (
                <ItemBox>
                  <ItemName>{name}</ItemName>
                  <ItemPrice>{price}DT</ItemPrice>
                  {
                  status?
                  <ItemStatus Color={status}>Payé</ItemStatus>:
                  <ItemStatus Color={status}>Non payé</ItemStatus>
                  }
                </ItemBox>
              )
            })}
            <SubType>
              <SubTypeTxt>Service</SubTypeTxt>
            </SubType>
            {trim[2]?.map(({name,price,status})=>{
              return (
                <ItemBox>
                  <ItemName>{name}</ItemName>
                  <ItemPrice>{price}DT</ItemPrice>
                  {
                  status?
                  <ItemStatus Color={status}>Payé</ItemStatus>:
                  <ItemStatus Color={status}>Non payé</ItemStatus>
                  }
                </ItemBox>
              )
            })}
            <SubType>
              <SubTypeTxt>Club</SubTypeTxt>
            </SubType>
            {trim[1]?.map(({name,price,status})=>{
              return (
                <ItemBox>
                  <ItemName>{name}</ItemName>
                  <ItemPrice>{price}DT</ItemPrice>
                  {
                  status?
                  <ItemStatus Color={status}>Payé</ItemStatus>:
                  <ItemStatus Color={status}>Non payé</ItemStatus>
                  }
                </ItemBox>
              )
            })}
          </TrimContainer>
        )
    }
  }
  useEffect(() => {
    console.log("trim1=>",trim1);
  }, [trim1])

  return (
    <Container>
      {renderAnnuelPay()}
      {renderTrimPay(trim1,"1er Trimestre")}
      {renderTrimPay(trim2,"2eme Trimestre")}
      {renderTrimPay(trim3,"3eme Trimestre")}
    </Container>
  )
}
export default Payment;