import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
`;
const HeaderSelector = styled.div`
  display:flex;
  flex-direction:row;
  background-color:white;
  width:50%;
  border-radius:12px;
  justify-content:space-between;
  padding:8px;
  align-items:center;
`;
const ArrowToLeft = styled.img`
  height:40px;
  width:auto;
`;
const ArrowToRight = styled.img`
  height:40px;
  width:auto;
`;
const TheTitle = styled.h2`
  color:gray;
`;
const CantineItem = styled.div`
  margin:15px;
  border-radius:8px;
  width:30%;
  max-width:35%;
  align-self:flex-start;
  align-self:center;
`;
const ElemBox = styled.div`
  background-color:white;
  box-shadow: 7px 6px 15px -8px rgba(0,0,0,0.8);
  display:flex;
  flex-direction:row;
  margin:15px;
  margin-bottom:0;
  border-radius:15px;
  width:50%;
  @media (max-width: 769px) {
    width: 80%;
  }

`;
const Box1 = styled.div`
  background-color:${props => props.Color ? props.Color :"#f39c12"};
  width:70px;
  height:50px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin:7px;
  border-radius:15px;
`;
const SubTitle = styled.h5`
  color:white;
  font-size:19px;
  text-align:center;
  margin:0;
`;
const Box2 = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left:5px;
    width:60%;
`;
const ElemTitle = styled.div`
  color:${props => props.Color ? props.Color :"#f39c12"};
`;
const ElemBody =styled.div`
  text-decoration:bold;
`;
const Box3 = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height:60px;
  width:60px;
`;
const ArrowIcon = styled.img`
  height:40px;
  width:40px;
`;
const ContentContainer= styled.div`
  background-color:white;
  box-shadow: 7px 6px 15px -8px rgba(0,0,0,0.8);
  padding:20px;
  border-radius:8px;
  width:100%;
`;
const ContentBody= styled.div`
  
`;
const FilesBox = styled.div`
  width:100%;
  height:auto;
  text-align:center;
`;
const LinkImg = styled.a`

`;
const ImgShowen = styled.img`
  width:47%;
  border:2px solid #f26522;
  height:auto;
`;

export {
  Container,
  HeaderSelector,
  ArrowToLeft,
  ArrowToRight,
  TheTitle,
  CantineItem,
  ElemBox,
  Box1,
  SubTitle,
  Box2,
  ElemTitle,
  ElemBody,
  Box3,
  ArrowIcon,
  ContentContainer,
  ContentBody,
  FilesBox,
  LinkImg,
  ImgShowen
}